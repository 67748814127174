import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet presenta un capell de fins a 15 cm de diàmetre que d’hemisfèric passa a aplanat i de color terrós rogenc a vinós, amb esquames blanquinoses quasi semicirculars. Les làmines són atapeïdes, amb lamel·les blanques amb taques rogenques amb l’edat. Les espores són blanques en massa, el·líptiques, de 8-10 x 6-7 micres. El peu, que és blanc-vermellós té un bulb gruixut i vermellós i porta un anell persistent, estriat, blanc a la part superior i del mateix color que el peu a l’inferior. La volva, a la base del peu, es desfà prest.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      